
























import {
	Vue,
	Component,
	Ref,
	PropSync,
	Watch,
	Prop,
} from 'vue-property-decorator'
import { Loja, Produto } from '@/models'
import { FindConfiguracaoDaContaUseCase, FindProdutoUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class BuscaDeProdutoPorCodBarras extends Vue {
	@Ref() campo!: HTMLInputElement
	@PropSync('value', { type: [Object, String], default: null })
	produto!: Produto | null
	@PropSync('searchInput') busca!: string | null
	@Prop() loja!: Loja;
	@Prop({ type: Boolean, default: true }) focarNoCampoDeBusca!: boolean
	searchInput!: string | null

	buscando = false
	result: number | null = null
	errors: string[] = []

	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	created() {
		this.buscarConfiguracao()
	}

	async buscarProduto() {
		if (!this.busca) return
		if (!this.loja) {
			AlertModule.setError('Selecione uma loja!')
			return
		}

		try {
			this.errors = []
			this.buscando = true
			this.$emit('carregando', this.buscando)
			const produto = await new FindProdutoUseCase().findProdutoComEstoque(
				this.busca,
				this.loja.id,
			)
			
			const tipos = ['Padrão', 'Composto', 'Variante']
			if (!tipos.includes(produto.tipo))
				throw new Error('Produto não encontrado')
			this.$emit('input', produto)
		} catch (error) {
			this.errors = error.response.data
			this.$emit('input', null)
		} finally {
			this.busca = ''
			this.buscando = false
			if (this.focarNoCampoDeBusca) {
				setTimeout(() => {
					this.campo.focus()
				})
			}
		}
	}

	focus() {
		this.campo.focus()
	}

	blur() {
		this.campo.blur()
	}

	async buscarConfiguracao() {
		try {
			const configuracaoDaConta =
				await this.findConfiguracaoDaContaUseCase.find()
			this.result =
				configuracaoDaConta?.eans + configuracaoDaConta?.medida || 13
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	@Watch('busca')
	onChangeBusca(busca: string) {
		if (busca.length == this.result) {
			this.buscarProduto()
		}
	}
}
