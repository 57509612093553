




































































































































































































































































import {
	imprimirEtiquetasDaGondola,
	imprimirEtiquetasDePrecosDoProduto,
	imprimirEtiquetasDeProduto,
	montarNomeEPrecoDaEtiqueta,
} from '@/shareds/etiquetas/impressao-de-etiquetas-de-preco'
import {
	EtiquetaDePreco,
	FiltrosDeEtiquetasDePreco,
	FormEtiquetaDePreco,
	ItemDaTabelaDePreco,
} from '@/models'
import { displayNomeCompletoDoProduto } from '@/shareds/produto-shareds'
import { Component, Ref, Vue, Watch} from 'vue-property-decorator'
import SeletorDeLojas from "@/components/loja/SeletorDeLojas.vue"
import BuscaDeProdutoDropdown from './BuscaDeProdutoDropdown.vue'
import BuscaDeProduto from '@/components/produto/BuscaDeProduto.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { TabelaDePreco } from '@/models'
import { EtiquetaDePrecoServiceAdapter } from '@/usecases'
import { obrigatorio } from '@/shareds/regras-de-form'
import { formatarMoeda } from '@/shareds/formatadores'
import { Inject } from 'inversify-props'
import request from '@/common/request'
import { isVestcasa } from '@/shareds/utils'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

const CAMPOS_DE_ETIQUETA = 'CAMPOS_DE_ETIQUETA'
@Component({
	components: {
		BuscaDeProdutoDropdown,
		SeletorDeLojas,
		BuscaDeProduto,
	},
})
export default class TelaDeEtiquetas extends Vue{
	@Ref() formularioDeProdutoEQuantidade!: HTMLFormElement
	@Ref() campoDeQuantidade!: HTMLInputElement
	@Ref() campoDeProduto!: HTMLInputElement
	@Ref() campoDeBuscaDoProduto!: BuscaDeProduto

	displayNomeCompletoDoProduto = displayNomeCompletoDoProduto
	tabelaSelecionadas: TabelaDePreco[] = []
	etiqueta: EtiquetaDePreco | null = null
	quantidade: number | '' | null = 1
	busca: string | null = null

	montarNomeEPrecoDaEtiqueta = montarNomeEPrecoDaEtiqueta
	formatarMoeda = formatarMoeda
	obrigatorio = obrigatorio
	precoDoProduto = false
	incluirNome = true
	formDeEtiquetas: FormEtiquetaDePreco[] = []
	vestcasa = isVestcasa

	filtros: FiltrosDeEtiquetasDePreco = localStorage[CAMPOS_DE_ETIQUETA]
		? JSON.parse(localStorage[CAMPOS_DE_ETIQUETA])
		: {
			layout: 'Etiqueta de Gôndola',
			loja: null,
			incluirPreco: false,
			idTabelas: null,
			produto: null,
			etiqueta: null,
		}

	@Inject('EtiquetaDePrecoServiceAdapter')
	private etiquetaDePrecoService!: EtiquetaDePrecoServiceAdapter
	
	get tabelas() {
		if (!this.filtros.loja) return []
		return this.filtros.loja.configuracaoDaLoja.tabelasDePreco
			.map(tabelaDaLoja => tabelaDaLoja.tabelaDePreco)
	}
	get nomeDaLoja(){
		if(!this.filtros.loja) return ''
		return this.filtros.loja.nome
	}
	
	get idDaLoja(){
		if (!this.filtros.loja) return null
		return this.filtros.loja.id
	}

	get buscaUnica() {
		return this.vestcasa
	}

	deleteItem(item: any) {
		const idx = this.formDeEtiquetas.indexOf(item)
		if (idx !== -1) {
			this.formDeEtiquetas.splice(idx, 1)
		}
	}

	formatarPrecoENomeDaEtiqueta(preco: ItemDaTabelaDePreco) {
		if(!preco) return ''
		return this.incluirNome 
			? `${this.precoDoProduto ? this.displayNomeDaEtiqueta(preco.tabela) : ''} ${this.precoDoProduto 
				? `- R$ ${formatarMoeda(preco.precoFinalVarejo)} - Limitação ${preco.qtdeLimitadaDoProduto}` 
				: ''}` 
			: `${this.precoDoProduto 
				? `R$ ${formatarMoeda(preco.precoFinalVarejo)} - Limitação ${preco.qtdeLimitadaDoProduto}` 
				: ''}`
	}
		
	displayNomeDaEtiqueta(tabela: TabelaDePreco){
		return tabela.tipoDeCliente?.nome || 'Varejo'
	} 
	
	incluirProduto() {
		if(!this.filtros.loja) {
			AlertModule.setError('Loja não selecionada')
			return
		}
		if (!this.formularioDeProdutoEQuantidade.validate()) return
		if (!this.filtros.produto) {
			return
		}
		if (!this.quantidade) {
			return
		}
		const stringified = JSON.stringify(this.filtros);
		localStorage.setItem(CAMPOS_DE_ETIQUETA, stringified)
		const etiquetaForm: FormEtiquetaDePreco = {
			layout: this.filtros.layout,
			idTabelas:  this.tabelaSelecionadas.map( ({ id}) => id),
			produtoId: this.filtros.produto.id,
			nomeDoProduto: displayNomeCompletoDoProduto(this.filtros.produto),
			quantidade: this.quantidade,
			lojaId: this.filtros.loja.id,
		}

		this.formDeEtiquetas.push(etiquetaForm)
		this.buscarEtiqueta(etiquetaForm)
		this.quantidade = 1
		this.filtros.produto = null
		setTimeout(() => {
			this.campoDeQuantidade.focus()
		})
	}

	async buscarEtiqueta(etiquetaForm: FormEtiquetaDePreco) {
		try {
			if (!etiquetaForm.idTabelas) return
			this.etiqueta = await this.etiquetaDePrecoService.create(etiquetaForm)
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	async imprimirEtiquetas() {
		if(!this.filtros.loja) {
			AlertModule.setError('Loja não selecionada')
			return
		}
		if(!this.formDeEtiquetas) {
			AlertModule.setError('Nenhum produto selecionado')
			return
		}
		try {
			let etiquetas: EtiquetaDePreco[] = []
			const tamanho = 10;
			for (let i = 0; i < this.formDeEtiquetas.length; i += tamanho) {
				const algunsFormsDeEtiqueta = this.formDeEtiquetas
					.slice(i, i + tamanho)
					.map(etiqueta => ({
						...etiqueta,
						idTabelas: this.tabelaSelecionadas.map(({ id }) => id),
						lojaId: this.idDaLoja,
						incluirPreco: this.filtros.incluirPreco,
					}));
				// eslint-disable-next-line no-await-in-loop
				const algumasEtiqueta = await request.post<never, EtiquetaDePreco[]>('/etiquetas', algunsFormsDeEtiqueta)
				etiquetas = [
					...etiquetas,
					...algumasEtiqueta,
				]
			}
			if (this.filtros.layout === 'Etiqueta de Produto') {
				await imprimirEtiquetasDeProduto(etiquetas, this.precoDoProduto, this.imprimirConformeRegraDeNegocioDoCaixa as boolean)	
			}
			if (this.filtros.layout === 'Etiqueta de Preços do Produto') {
				await imprimirEtiquetasDePrecosDoProduto(etiquetas, this.precoDoProduto, this.incluirNome, this.imprimirConformeRegraDeNegocioDoCaixa as boolean)
			}
			if(this.filtros.layout === 'Etiqueta de Gôndola') {
				await imprimirEtiquetasDaGondola(etiquetas, this.precoDoProduto, this.incluirNome, this.imprimirConformeRegraDeNegocioDoCaixa as boolean)	
			}
		} catch (error) {
			AlertModule.setError(error)
		}
	}
	
	get imprimirConformeRegraDeNegocioDoCaixa() {
		return UserLoginStore.permiteRegraDeNegocio('imprimir-precos-das-etiquetas-conforme-regra-do-caixa')
	}

	@Watch('tabelaSelecionadas', { deep: true })
	onChangePrecoDoProduto() {
		if (this.tabelaSelecionadas.length != 0 && !this.imprimirConformeRegraDeNegocioDoCaixa) {
			this.precoDoProduto = false
		}
	}
}
